<template>
  <div>
    <div class="back-top" @click="backTop" v-if="showBtn">
      <div class="BJ"></div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      showBtn: false,
    };
  },
  methods: {
    showbtn() {
      let that = this;
      let scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop;
      that.scrollTop = scrollTop;
      if (scrollTop > 1080 * 2) {
        this.showBtn = true;
      } else {
        this.showBtn = false;
      }
    },

    backTop() {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    },
  },
  mounted() {
    window.addEventListener("scroll", this.showbtn, true);
  },
};
</script>

<style lang='scss'>
.back-top {
  width: 52px;
  height: 52px;
  /* background: url("../../assets/image/top.png") no-repeat; */
  /* background-position: center; */
  /* background-size: 16px 16px; */
  position: fixed;
  bottom: 40px;
  right: 46px;
  z-index: 99999;
  box-shadow: 0px 0px 8px 0px rgba(113, 124, 156, 0.2);
  border-radius: 6px 6px 6px 6px;
  opacity: 1;
  background: #ffffff;
  display: flex;
  justify-content: space-around;
  align-items: center;
  > .BJ {
    width: 16px;
    height: 16px;
    background: url("../../assets/image/top.png") no-repeat;
    background-position: center;
    background-size: 100% 100%;
  }
}
.back-top:hover .BJ{
  background: url("../../assets/image/hoverTop.png") no-repeat;
  background-size: 16px 16px;
  // background-position: center;
}
</style>