<template>
    <div class="floatbar">
        <div class="floatbar_list">
            <div class="iconfont icon-rongqi floatbar_list_one">
                <div class="floatbar_list_one_consult">
                    欢迎咨询
                    <span class="floatbar_list_triangle"></span>
                </div>
            </div>
            <div class="iconfont icon-a-rongqi18 floatbar_list_one">
                <div class="floatbar_list_one_consult floatbar_list_one_telephone">
                    <p>联系电话</p>
                    <p>15920097291</p>
                    <span class="floatbar_list_triangle"></span>
                </div>

            </div>
            <div class="iconfont icon-rongqi-2 floatbar_list_one">
                <div class="floatbar_list_one_consult floatbar_list_one_code">
                    <img src="../assets/image/code.jpg" alt="" class="floatbar_list_one_picture">
                    <span class="floatbar_list_triangle"></span>
                </div>
            </div>
            <router-link :to="`${rootLink}/apply`">
                <div class="iconfont icon-rongqi-3 floatbar_list_one"></div>
            </router-link>

        </div>
        <!-- <div class="floatbar_top iconfont icon-a-rongqi31" v-show="returnTop" @click="goTop"></div> -->
    </div>
</template>
<script >
export default {
    data() {
        return {
            returnTop: false,
            rootLink: '/simple'
        }
    },
    watch: {
        $route: {
            handler: function (to) {
                this.handleRoute(to)
            },
        immediate: true,
        }
    },
    mounted() {
        this.handleRoute()
        // this.handleScroll()
    },
    destroyed() {
        // window.removeEventListener('scroll', () => { }); // 离开当前组件别忘记移除事件监听
    },
    methods: {
        handleRoute() {
            const path = this.$route.path
            if (path.includes('/simplev')) {
                this.rootLink = '/simplev'
            } else if (path.includes('/iot')) {
                this.rootLink = '/iot'
            } else if (path.includes('/cim')) {
                this.rootLink = '/cim'
            }
        },
        handleScroll() {
            window.addEventListener('scroll', () => {
                let scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop
                // console.log(scrollTop, '滚动距离')
                if (scrollTop > 200) {
                    this.returnTop = true
                } else {
                    this.returnTop = false
                }
            });
        },
        goTop() {
            // document.body.scrollTop = 0;
            //         document.documentElement.scrollTop = 0;
            window.scrollTo({
                top: 0,
                behavior: "smooth"
            })
        }
    },
}

</script>
<style lang="less" scoped>
a{
    text-decoration: none;
}
.floatbar {
    position: fixed;
    right: 46px;
    bottom: 112px;
    z-index: 10;

    .floatbar_list {
        width: 52px;
        height: 250px;
        border-radius: 6px;
        background: #FFFFFF;
        box-shadow: 0px 10px 40px 1px rgba(97, 117, 147, 0.2);
        display: flex;
        justify-content: space-around;
        flex-direction: column;
        align-items: center;
        margin-bottom: 67px;

        .floatbar_list_one {
            width: 24px;
            height: 24px;
            color: #404040;
            font-size: 24px;
            position: relative;

            .floatbar_list_one_consult {
                position: absolute;
                right: 70px;
                top: -24px;
                width: 130px;
                height: 70px;
                border-radius: 8px;
                background: #FFFFFF;
                font-family: PingFangRegular;
                font-size: 16px;
                color: #242424;
                text-align: center;
                line-height: 70px;
                display: none;

                .floatbar_list_triangle {
                    position: absolute;
                    right: -12px;
                    top: 13px;
                    border-left: 20px solid #FFFFFF;
                    border-top: 20px solid transparent;
                    border-bottom: 20px solid transparent;
                }
            }

            .floatbar_list_one_telephone {
                width: 182px;
                height: 102px;
                top: -30px;
                padding: 24px 0 24px 31px;
                line-height: 25px;
                text-align: left;

                .floatbar_list_triangle {
                    right: -12px;
                    top: 29px;
                }
            }

            .floatbar_list_one_code {
                width: 140px;
                height: 140px;
                padding: 11px;
                top: -50px;

                .floatbar_list_triangle {
                    right: -12px;
                    top: 45px;
                }
            }

            &:hover {
                color: #0A86FF;
            }

            &:hover .floatbar_list_one_consult {
                display: block;
            }
        }

        .floatbar_list_one_picture {
            width: 119px;
            height: 119px;
        }
    }

    .floatbar_top {

        width: 52px;
        height: 52px;
        background: #FFFFFF;
        border-radius: 50%;
        font-size: 25px;
        text-align: center;
        line-height: 52px;
        position: absolute;
        bottom: 0;
        left: 0;

        &:hover {
            color: #0A86FF;
        }

    }
}
</style>