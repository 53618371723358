<template>
  <div class="footer_box" style="min-width: 1920px;">
    <subscribe v-if="type == 1"></subscribe>
    <subscribe2 v-else-if="type == 2"></subscribe2>
    <subscribe3 v-else-if="type == 3"></subscribe3>
    <div class="footer_box_bootom" v-if="!this.$route.query.type">
      <section class="ysj-footer-box">
        <section class="ysj-footer-left">
          <img src="./imgs/LogoTemporary2@2x.png" alt="" />
        </section>
        <section class="ysj-footer-right">
          <div class="ysj-produce-group" >
            <div class="ysj-produce-item" v-for="i in produces" :key="i.name"  @click="onClickOpen(i)">
              <span>{{ i.name }}</span>
              <span>{{ i.subName }}</span>
            </div>
          </div>
          <div class="ysj-info">
            <span>关注或联系我们</span>

            <el-tooltip
              class="item"
              effect="light"
              placement="left"
            >
              <div slot="content">
                <img
                  style="width: 100px; height: 100px"
                  src="./imgs/GongzhonghaoPic@2x.png"
                  alt=""
                />
              </div>
              <img src="./imgs/GongzhonghaoPic@2x.png" alt="" />
            </el-tooltip>

            <span>咨询热线</span>
            <span>0755-2830 9272 </span>
            <span>主体公司：深圳原世界科技有限公司</span>
            <span>
              Copyright 2023 Shenzhen YuanWorld Technology Co，Ltd.保留所有权
              深圳原世界科技有限公司 粤ICP备14036540号
            </span>
          </div>
        </section>
      </section>
    </div>


    <div class="footer_box_bootom2" v-else>
      
      <img src="./imgs/LogoTemporary2@2x.png" alt="" class="imgs" />

      <div class="ysj-info">
            <span>关注或联系我们</span>

            <el-tooltip
              class="item"
              effect="light"
              placement="left"
            >
              <div slot="content">
                <img
                  style="width: 100px; height: 100px"
                  src="./imgs/GongzhonghaoPic@2x.png"
                  alt=""
                />
              </div>
              <img src="./imgs/GongzhonghaoPic@2x.png" alt="" />
            </el-tooltip>

            <span>咨询热线</span>
            <span>0755-2830 9272 </span>
            <span>主体公司：深圳原世界科技有限公司</span>
            <span>
              Copyright 2023 Shenzhen YuanWorld Technology Co，Ltd.保留所有权
              深圳原世界科技有限公司 粤ICP备14036540号
              </span>
          </div>
    </div>



  </div>
</template>
  <script>
import subscribe from "./subscribe.vue";
import subscribe2 from "./subscribe2.vue";
import subscribe3 from './subscribe3.vue'
export default {
  data() {
    return {
      produces: [
        {
          name: "Yuan World Engine",
          subName: "（三维数据引擎平台）",
          url: "https://3dcloud.yuanworldcloud.com/#/home",

        },
        {
          name: "Yuan World Cloud",
          subName: "（三维云渲染引擎平台）",
          url: "https://3dcloud.yuanworldcloud.com/#/home",
        },
        {
          name: "Yuan World Simple IOT",
          subName: "（物联感知使能平台）",
          url: "https://iot.yuanworldcloud.com/#/iot/home",
        },
        {
          name: "Yuan World SimpleV",
          subName: "（数据可视化平台）",
          url: "https://simplev.yuanworldcloud.com/#/home",
        },
        {
          name: "Yuan World CIM",
          subName: "（城市信息CIM平台）",
          url: "https://cim.yuanworldcloud.com/#/cim/home",
        },
      ],
    };
  },
  components: {
    subscribe,
    subscribe2,
    subscribe3,
  },
  props:{
    type:{
      default:1
    }
  },
  methods:{
    onClickOpen(i){
      window.open(i.url);
    }
  }
};
</script>







  <style lang="scss" scoped>
@import "@/assets/css/font.css";
.footer_box_bootom {
  width: 100%;
  height: 320px;
  background: url("./imgs/BottomBg@2x.png") no-repeat 100% 100%;
  background-size: 100% 100%;
}
.ysj-footer-box {
  width: 1600px;
  margin: auto;
  padding: 45px 0;
  display: flex;
  .ysj-footer-left {
    img {
      width: 150px;
      height: 50px;
      margin-right: 77px;
    }
  }
  .ysj-footer-right {
    flex: 1;
    display: flex;
    flex-direction: column;
    .ysj-produce-group {
      display: flex;
      margin-top: 16px;
      .ysj-produce-item {
        // flex: 20%;
        position: relative;
        text-align: center;
        cursor: pointer;
        &::before {
          content: "";
          display: block;
          width: 2px;
          height: 14px;
          background: #323d4d;
          position: absolute;
          top: 12px;
          left: -59px;
        }
        &:nth-child(1)::before {
          display: none;
        }
        &:nth-child(2),
        &:nth-child(4) {
          margin: 0 120px;
        }

        span {
          display: block;
          &:nth-child(1) {
            font-family: PingFang-Medium;
            font-size: 16px;
            font-weight: bold;
            color: #fff;
            line-height: 23px;
            margin-bottom: 6px;
          }
          &:nth-child(2) {
            font-family: PingFang-Medium;
            font-size: 12px;
            font-weight: 400;
            color: #9ea2b2;
            line-height: 18px;
          }
        }
      }
    }
    .ysj-info {
      position: relative;
      margin-top: 60px;
      span {
        position: absolute;
      }
      span:nth-child(1) {
        font-family: PingFang-Medium;
        font-size: 14px;
        font-weight: 400;
        color: #fff;
        line-height: 20px;
        top: 0;
        left: 0;
      }
      img {
        width: 60px;
        height: 60px;
        position: absolute;
        left: 0;
        top: 30px;
      }
      span:nth-child(3) {
        font-family: PingFang-Medium;
        font-size: 14px;
        font-weight: 500;
        color: #9ea2b2;
        line-height: 20px;
        top: 50px;
        left: 110px;
        &::before {
          content: "";
          display: inline-block;
          width: 20px;
          height: 20px;
          background: url("./imgs/iconBottomTel@2x.png") no-repeat center center;
          background-size: 100% 100%;
          position: absolute;
          top: 0;
          left: -25px;
        }
      }
      span:nth-child(4) {
        font-family: PingFang-Medium;
        font-size: 16px;
        font-weight: 400;
        color: #d1d1e4;
        line-height: 22px;
        top: 74px;
        left: 84px;
      }
      span:nth-child(5) {
        font-family: PingFang-Medium;
        font-size: 14px;
        font-weight: 400;
        color: #686f7e;
        line-height: 20px;
        top: 46px;
        left: 290px;
      }
      span:nth-child(6) {
        font-family: PingFang-Medium;
        font-size: 14px;
        font-weight: 400;
        color: #686f7e;
        line-height: 20px;
        top: 76px;
        left: 290px;
      }
    }
  }
}



.footer_box_bootom2 {
  width: 100%;
  height: 160px;
  background: url("./imgs/BottomBg@2x.png") no-repeat 100% 100%;
  background-size: 100% 100%;
  padding-top:25px;
  box-sizing: border-box;
  position: relative;
  .imgs{
    width: 150px;
      height: 50px;
      position: absolute;
      top: 40px;
      left: 150px;
  }
  .ysj-info {
      position: relative;
      // margin-top: 60px;
      width: 910px;
      height: 160px;
      margin: auto;
      // background: red;
      span {
        position: absolute;
      }
      span:nth-child(1) {
        font-family: PingFang-Medium;
        font-size: 14px;
        font-weight: 400;
        color: #fff;
        line-height: 20px;
        top: 0;
        left: 0;
      }
      img {
        width: 60px;
        height: 60px;
        position: absolute;
        left: 0;
        top: 30px;
      }
      span:nth-child(3) {
        font-family: PingFang-Medium;
        font-size: 14px;
        font-weight: 500;
        color: #9ea2b2;
        line-height: 20px;
        top: 50px;
        left: 110px;
        &::before {
          content: "";
          display: inline-block;
          width: 20px;
          height: 20px;
          background: url("./imgs/iconBottomTel@2x.png") no-repeat center center;
          background-size: 100% 100%;
          position: absolute;
          top: 0;
          left: -25px;
        }
      }
      span:nth-child(4) {
        font-family: PingFang-Medium;
        font-size: 16px;
        font-weight: 400;
        color: #d1d1e4;
        line-height: 22px;
        top: 74px;
        left: 84px;
      }
      span:nth-child(5) {
        font-family: PingFang-Medium;
        font-size: 14px;
        font-weight: 400;
        color: #686f7e;
        line-height: 20px;
        top: 46px;
        left: 290px;
      }
      span:nth-child(6) {
        font-family: PingFang-Medium;
        font-size: 14px;
        font-weight: 400;
        color: #686f7e;
        line-height: 20px;
        top: 76px;
        left: 290px;
      }
    }
}









</style>
<style lang="scss">
.el-tooltip__popper {
  padding: 0 !important;
  border: none !important;
}
</style>