import TweenMax from 'TweenMax'
import TweenLite from 'TweenLite'
import TimelineMax from 'TimelineMax'
import ScrollMagic from 'ScrollMagic'
import 'ScrollMagicAddIndicators'
import 'ScrollMagicGSAP'

const TodeAnimate = {
  TweenMax: TweenMax,
  TweenLite: TweenLite,
  TimelineMax: TimelineMax,
  Scene: ScrollMagic.Scene,
  controller: null
}

TodeAnimate.Controller = function (options) {
  TodeAnimate.controller = new ScrollMagic.Controller(options)
}

export default TodeAnimate
