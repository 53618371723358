<template>
  <div :class="homeFlag ? '' : 'haeder_height'">
    <div
      class="haeder"
      :class="homeTop ? '' : 'haeder_top'"
      ref="header"
      :style="cardTypeStyle"
      @mouseenter="onMouseHandler"
      @mouseleave="onMouseleaveHand"
    >
      <slot :homeTop="homeTop"></slot>
      <div class="rightItem">
        <div class="selectBox">
          <div v-for="(item, index) in dataList" :key="item.name">
            <div
              v-if="item.status == 1"
              :class="{
                text: true,
                selectColor: selectIndex == item.url,
                defa: def && homeTop
              }"
              @click="changeIndex(item)"
            >
              {{ item.name }}
            </div>
            <el-dropdown
              v-if="item.status == 2"
              @command="handleCommand"
              placement="bottom"
            >
              <span
                class="el-dropdown-link text"
                :class="{ selectColor: selectIndex == item.url }"
                @click="changeIndex(index)"
              >
                {{ item.name }}<i class="el-icon-arrow-down el-icon--right"></i>
              </span>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item
                  :style="{
                    backgroundImage: `url(${require('./imgs/' +
                      (index + 1) +
                      '.png')})`,
                    color: item.color
                  }"
                  :command="item.url"
                  v-for="(item, index) in item.children"
                  :key="item.name"
                >
                  {{ item.name }}
                </el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </div>
        </div>
        <router-link target="_blank" :to="to">
          <div class="rightText">申请使用</div>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      homeFlag: true,
      homeTop: true,
      selectIndex: '/',
      scorllstata: false
    }
  },
  props: {
    to: {
      type: String,
      default: '/simplev/apply'
    },
    dataList: {
      type: Array
    },
    bgcolors: {
      type: String,
      default: '#fff'
    },
    fontColor: {
      type: String,
      default: '#212121'
    },
    borcol: {
      type: String,
      default: '#e1e2e3'
    },
    def: {
      type: Boolean,
      default: false
    }
  },
  watch: {
    $route: {
      handler: function (to) {
        if (to.path == '/simplev/home' || to.path == '/cim/home') {
          this.homeFlag = true
        } else {
          this.homeFlag = false
        }

        if (
          to.path == '/simplev/videoCourse' ||
          to.path == '/simplev/help' ||
          to.path == '/simplev/information' ||
          to.path == '/simplev/questions'
        ) {
          this.selectIndex = '/simplev/visualization'
        } else if (to.path == '/simplev/case/detalis') {
          this.selectIndex = '/simplev/case/list'
        } else {
          this.selectIndex = to.path
        }
      },
      immediate: true
    }
  },
  mounted() {
    // 向页面添加股东事件
    window.addEventListener('scroll', this.handleScroll, true)
  },
  destroyed() {
    //离开这个界面之后，删除滚动事件，不然容易除bug
    window.removeEventListener('scroll', this.handleScroll)
  },
  methods: {
    handleScroll() {
      if (!this.homeFlag) return
      var scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop
      let c = document.getElementById('bigBox')?.offsetTop
      if (scrollTop < c) {
        this.homeTop = true
        this.scorllstata = false
        // console.log(linkActive);
        // this.$refs.header &&
        // (this.$refs.header.style.background = "rgba(255,255,255,0.1)");
      } else {
        this.homeTop = false
        this.scorllstata = true
        // this.$refs.header && (this.$refs.header.style.background = "#fff");
      }
    },
    goHome(url) {
      this.$router.push(url)
    },
    handleCommand(command) {
      if (!command) return
      this.$router.push(command)
      this.selectIndex = command
    },
    changeIndex(e) {
      console.log(e)
      if (!e.url) return

      this.$router.push(e.url)
      this.selectIndex = e.url
    },

    onMouseHandler() {
      // if(this.homeTop){
      //   this.homeTop = false
      // }
      // console.log(this.scorllstata,this.homeTop,this.def)
      if (this.scorllstata) {
        if (this.homeTop) {
          this.homeTop = false
        }
      } else {
        this.homeTop = false
      }
    },
    onMouseleaveHand() {
      // this.homeTop = true
      if (this.scorllstata) {
        if (this.homeTop) {
          this.homeTop = true
        }
      } else {
        this.homeTop = true
      }
    }
  },
  computed: {
    cardTypeStyle: function () {
      return {
        '--background': this.bgcolors,
        '--fontcolor': this.fontColor,
        '--borcolor': this.borcol
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.haeder_height {
  height: 70px;

  .haeder {
    background-color: #fff;
    border-bottom: 1px solid var(--borcolor);
  }
}

.haeder_top {
  background-color: var(--background);
  border-bottom: 1px solid var(--borcolor);
}

.haeder {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  min-width: 1400px;
  width: 100%;
  height: 70px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  &:hover {
    background: var(--background);
    box-shadow: 0px 4px 10px 0px rgba(69, 106, 150, 0.1);
    border-bottom: 1px solid var(--borcolor);
  }

  .rightItem {
    display: flex;
    align-items: center;

    .rightText {
      width: 120px;
      height: 70px;
      background-color: #337dff;
      text-align: center;
      line-height: 70px;
      cursor: pointer;
      font-size: 16px;
      font-family: PingFang-Medium;
      font-weight: 400;
      color: #ffffff;
    }
  }
}

.el-dropdown-link {
  cursor: pointer;
  color: #666666;
  font-size: 16px;
  outline: none;
}

.selectBox {
  display: flex;

  .text {
    cursor: pointer;
    margin: 0 35px;
    color: #212121;
    font-size: 16px;
  }
  .text.defa {
    color: rgba(227, 227, 228, 0.8);
  }
  .text-lig {
    cursor: pointer;
    margin: 0 35px;
    color: #212121;
    font-size: 16px;
  }

  .text:hover {
    color: #2461e7;
  }

  .selectColor {
    color: #2461e7;
  }
}

.el-dropdown-link .el-icon-arrow-down {
  margin-left: 4px;
}

.el-dropdown-menu {
  display: flex;
  // 四个类的宽度
  // width: 704px;
  // 三个类的宽度
  width: 528px;
  align-items: center;
  padding: 0 14px;
  justify-content: space-between;
  height: 128px;
  border-radius: 8px;
  margin-top: 29px !important;
}

.el-dropdown-menu__item {
  padding: 33px 0 0 13px;
  font-size: 16px;
  width: 160px;
  height: 100px;
  background-repeat: no-repeat;
  background-size: 100% 100%;
}

.el-dropdown-menu__item:focus,
.el-dropdown-menu__item:not(.is-disabled):hover {
  background-color: transparent;
}
</style>
