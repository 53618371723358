<template>
    <div class="fade_box" ref="fadesBox">
        <div class="fade animate__animated" ref="fades">
            <slot></slot>
        </div>
    </div>
</template>
<script>
import animate from '../../unit/js/animate'
export default {
    name: 'tode-fade',
    data() {
        return {
            isFade:false
        }
    },
    props: {
        times: {
            type: Number,
            default: 0.5
        },
        // time 是时间动画  scoll 滚动条  fixed固定滚动
        type: {
            type: String,
            default: 'scoll'
        },
        // 透明度值
        opacityNum: {
            type: Number,
            default: 1
        },
        // 延迟高度
        offsetNum: {
            type: Number,
            default: 0
        },
        // 动画高度
        durationNum: {
            default: 10
        },
        triggerPos: {
            default: 0.9
        }
    },
    mounted() {
        // this.fadeIn()
        this.fadeIn2()
    },
    methods: {
        fadeIn() {
            // 渐现组件
            const el = this.$refs.fades
            const offset = this.type === 'fixed' ? el.offsetHeight / 2 : this.offsetNum
            const duration = this.durationNum || el.offsetHeight / 2
            if (this.type === 'scoll') {
                const tween = animate.TweenMax.to(el, this.times, { opacity: this.opacityNum })
                const scene = new animate.Scene({ triggerElement: el, duration: duration, offset: offset }).setTween(tween)
                    .on('start', e => {
                        console.log('start')
                    })
                    .on('end', e => {
                        console.log('end')
                    })
                    .addTo(animate.controller)
                scene.triggerHook(this.triggerPos)
            } else if (this.type === 'fixed') {
                const tween = animate.TweenMax.to(el, this.times, { opacity: this.opacityNum })
                const scene = new animate.Scene({ triggerElement: el, duration: duration, offset: offset }).setPin(el).setTween(tween)
                    .on('start', e => {
                        this.$emit('start', e)
                    })
                    .on('end', e => {
                        this.$emit('end', e)
                    })
                    .addTo(animate.controller)
                scene.triggerHook(this.triggerPos)
            } else {
                const scene = new animate.Scene({ triggerElement: el }).setClassToggle(el, 'fade-active').addTo(animate.controller)
                scene.triggerHook(this.triggerPos)
            }
        },
        fadeIn2(){
            const el = this.$refs.fades
            const elBox=this.$refs.fadesBox
            const scene = new animate.Scene({ triggerElement: elBox }).setClassToggle(el, 'animate__fadeInUp').addTo(animate.controller)
            scene.triggerHook(this.triggerPos)
        },
        fadeIn3(){
            const el = this.$refs.fades
            const scene = new animate.Scene({ triggerElement: el  })
                .on('start', e => {
                    if(e.state=="DURING"){
                        this.isFade=true
                        console.log("DURING")
                    }else if(e.state=="BEFORE"){
                        console.log("BEFORE")
                        this.isFade=false
                    }
                })
                .addTo(animate.controller)
            scene.triggerHook(this.triggerPos)
        }
    }
}
</script>
<style lang="less" scoped>
@keyframes fadeTime {
    100% {
        opacity: 1;
    }
}

.fade {
    opacity: 0;
}

.fade-active {
    animation: fadeTime 1s;
    animation-fill-mode: forwards;
}

</style>
  