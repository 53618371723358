<template>
  <div id="app" class="scrollbar-xs">
    <backTop></backTop>
    <router-view></router-view>
    <floatbar></floatbar>
  </div>
</template>

<script>
import backTop from "@/components/backTop/indexPage.vue";
import floatbar from "./components/floatbar.vue"

export default {
  name: "App",
  data() {
    return {

    };
  },
  components: {
    backTop,
    floatbar
  },
};
</script>

<style>
* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  font-family: PingFang-Medium;
}
a {
  text-decoration: none;
}
::-webkit-scrollbar {
  display: none; /* Chrome Safari */
     


  
}
#app {
  width: 100%;
  /* width: 1920px;
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; */
  /* text-align: center; */
  /* color: #2c3e50; */
  /* margin-top: 60px; */
  /* margin: 0;
  padding: 0;
  position: relative; */
}

.scrollbar-xs::scrollbar {
  /* height: 0.2rem; */
  height: 0;
  width: 10px;
  /* background: red; */
}
.scrollbar-xs::scrollbar-thumb {
  border-radius: 3px;
  height: 100px; /* 滚动条滑块长度 */
  background-color: #ccc;
}

/* 处理出现横向滚动条 */
/* html {
  overflow-y: scroll;
} */

:root {
  overflow-y: auto;
  overflow-x: hidden;
}

:root body {
  position: absolute;
}

body {
  width: 100vw;
  overflow: hidden;
}

</style>
