import Vue from 'vue'
import VueRouter from 'vue-router'
import Index from '../views/simpleV/index/index.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/simplev',
    component: Index,
    redirect: '/simplev/home',
    children: [
      {
        path: 'home',
        name: 'Home',
        component: () => import('../views/simpleV/home/indexPage.vue')
      },
      {
        path: 'questions', //问答中心
        name: 'questions',
        component: () => import('../views/simpleV/questions/questions.vue')
      },
      {
        path: 'information', //资料中心
        name: 'information',
        component: () => import('../views/simpleV/information/information.vue')
      },
      {
        path: 'help', //帮助文档
        name: 'help',
        component: () => import('../views/simpleV/help/help.vue')
      },
      {
        path: 'videoCourse', //视频中心
        name: 'videoCourse',
        component: () => import('../views/simpleV/videoCourse/videoCourse.vue')
      },
      {
        path: 'material', //素材中心
        name: 'material',
        component: () => import('../views/simpleV/material/material.vue')
      },
      {
        path: 'case', //行业案例
        name: 'caseIndex',
        component: () => import('../views/simpleV/case/index.vue'),
        children: [
          {
            path: 'list', //
            component: () => import('../views/simpleV/case/case.vue')
          },
          {
            path: 'detalis', //
            component: () => import('../views/simpleV/case/caseDetalis.vue')
          }
        ]
      },
      {
        path: 'product', //产品服务
        name: 'product',
        component: () => import('../views/simpleV/product/product.vue')
      },
      {
        path: 'apply', //申请
        name: 'apply',
        component: () => import('../views/simpleV/apply/apply.vue')
      },
      {
        path: 'about', //关于我们
        name: 'about',
        component: () => import('../views/simpleV/about/about.vue')
      },
      {
        path: 'products', //iot  更多产品
        component: () => import('../components/moreProducts/indexPage.vue')
      }
    ]
  },
  {
    path: '/iot', //iot首页
    redirect: '/iot/home',
    component: () => import('../views/IOT/index/index.vue'),
    children: [
      {
        path: 'home', //iot首页
        component: () => import('../views/IOT/home/home.vue')
      },
      {
        path: 'about', //iot 关于我们
        component: () => import('../views/simpleV/about/about.vue')
      },
      {
        path: 'apply', //申请
        component: () => import('../views/simpleV/apply/apply.vue')
      },
      {
        path: 'help', //iot 帮助中心
        component: () => import('../views/IOT/help/help.vue')
      },
      {
        path: 'case', //iot 解决方案
        component: () => import('../views/IOT/case/case.vue')
      },
      {
        path: 'productService', //IOT 产品与服务
        component: () => import('../views/IOT/productService/indexPage.vue'),
        redirect: 'productService/Internet',
        children: [
          {
            path: 'Internet',
            name: 'Internet',
            component: () =>
              import('../views/IOT/productService/Internet/indexPage.vue')
          },
          {
            path: 'smallSrogram',
            name: 'smallSrogram',
            component: () =>
              import('../views/IOT/productService/smallSrogram/indexPage.vue')
          }
        ]
      },
      {
        path: 'shopping', //iot 生态商城
        component: () => import('../views/IOT/shopping/shopping.vue')
      },
      {
        path: 'business', //iot 行业生态
        component: () => import('../views/IOT/business/business.vue')
      },
      {
        path: 'products', //iot  更多产品
        component: () => import('../components/moreProducts/indexPage.vue')
      }
    ]
  },

  {
    path: '/cim', //cim首页
    redirect: '/cim/home',
    component: () => import('../views/CIM/index'),

    children: [
      {
        path: 'home', //cim首页
        component: () => import('../views/CIM/home/indexPage.vue'),
        meta: {
          headShow: true
        }
      },
      {
        path: 'engine', //cim三维
        component: () => import('../views/CIM/ThreeEngine'),
        meta: {
          headShow: true
        }
      },
      {
        path: 'visua', //cim可视化
        component: () => import('../views/CIM/visualization'),
        meta: {
          headShow: true
        }
      },
      {
        path: 'about', //cim 关于我们
        component: () => import('../views/simpleV/about/about.vue'),
        meta: {
          headShow: true
        }
      },
      {
        path: 'apply', //申请
        component: () => import('../views/simpleV/apply/apply.vue'),
        meta: {
          headShow: true
        }
      },
      {
        path: 'products', //cim  更多产品
        component: () => import('../components/moreProducts/indexPage.vue'),
        meta: {
          headShow: true
        }
      },
      {
        path: 'case', //行业案例
        name: 'caseIndex2',
        component: () => import('../views/simpleV/case/index.vue'),
        meta: {
          headShow: true
        },
        children: [
          {
            path: 'list', //
            component: () => import('../views/simpleV/case/case.vue'),
            meta: {
              headShow: true
            }
          },
          {
            path: 'detalis', //
            component: () => import('../views/simpleV/case/caseDetalis.vue'),
            meta: {
              headShow: true
            }
          }
        ]
      }
    ]
  },

  {
    path: '/video', //cim首页
    component: () => import('../components/CimComponents/head/video.vue')
  },
  {
    path: '/yw/apply', //申请
    name: 'ywapply',
    component: () => import('../views/simpleV/apply/apply.vue')
  },

  // {
  //   path: '/cimnew',  //cim首页
  //   redirect: '/cimnew/home',
  //   component: () => import('../views/CIM/index'),

  //   children:[
  //     {
  //       path: 'home',  //cim首页
  //       component: () => import('../views/CIM/home/indexPage.vue'),
  //       meta:{
  //         headShow:false
  //       },
  //     },
  //   ]

  // }
]

const router = new VueRouter({
  routes,
  scrollBehavior(to, from, saveScrollPosition) {
    return { x: 0, y: 0 }
  }
})

const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

export default router
