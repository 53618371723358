import Vue from 'vue'
import App from './App.vue'
import ElementUI from 'element-ui';
import router from './router'
import 'element-ui/lib/theme-chalk/index.css';
import 'normalize.css/normalize.css'
import "./assets/css/font.css"

import "./assets/iconfont/iconfont.css"
import todeFade from "./components/fade/fade.vue"


// import rem from 'amfe-flexible'   //使用插件设置基准为浏览器大小/10
// Vue.use(rem)

import './rem'

import TodeAnimate from "./unit/js/animate"
TodeAnimate.Controller({ addIndicators: false })

import 'animate.css';
// Vue.use(animate);
import './unit/js/video'
Vue.config.productionTip = false
Vue.use(ElementUI);

Vue.prototype.$bus=new Vue()
Vue.component('todeFade',todeFade)
new Vue({
  router,
  render: h => h(App),
}).$mount('#app')
