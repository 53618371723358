<template>
  <div class="subscribe3">
    <a class="names">数字孪生世界全流程一站式运行支撑平台</a>
    <button>
      <router-link target="_blank" to="/cim/apply"><span class="subscribe_box_btn" >立即体验</span></router-link>
    </button>
  </div>
</template>

<script>
export default {
  name: "subscribe3",
};
</script>

<style lang="less" scoped>
.subscribe3 {
  width: 100%;
  height: 300px;
  background: url("../../assets/cim/imgs/fotbg.png") no-repeat;
  background-size: 100% 100%;
  position: relative;
  .names {
    //             width: 661px;
    // height: 50px;
    font-size: 36px;
    font-family: PingFang-Medium;
    font-weight: normal;
    color: rgba(28, 28, 28, 0.9);
    position: absolute;
    top: 96px;
    left: 237px;
  }
  > button {
    cursor: pointer;
    position: absolute;
    top: 180px;
    left: 237px;
    width: 130px;
    height: 45px;
    background: url("../../assets/cim/imgs/btnbg.png") no-repeat;
    background-size: 100% 100%;
    border: none;
    font-size: 20px;
    font-family: PingFang-Medium;
    font-weight: normal;
    color: #ffffff;
    .subscribe_box_btn{
      color: #ffffff;
    }

    transition: all 0.2s linear;
    &:hover {
      box-shadow: 0px 4px 8px 0px rgba(70, 129, 225, 0.3);
      transform: translate3d(0, -5px, 0);
    }
  }
}
</style>