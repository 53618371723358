<template>
    <div>
        <heads :dataList="titleArr" to="/simplev/apply">
            <img src="../../../assets/image/svlogo1.png" alt="" class="logo" @click="goHome">
        </heads>
        <router-view></router-view>
        <Footer v-if="footerFlag"></Footer>
    </div>
</template>
<script>
import heads from "@/components/head/indexPage.vue";
import Footer from "@/components/footer/indexPage.vue";
export default {
    data() {
        return {
            url: require("../../../../public/logo.png"),
            footerFlag:true,
            titleArr: [
                { name: "首页", status: 1, url: "/simplev/home" },
                { name: "产品服务", status: 1, url: "/simplev/product" },
                { name: "行业案例", status: 1, url: "/simplev/case/list" },
                { name: "素材中心", status: 1, url: "/simplev/material" },
                {
                    name: "可视化学院",
                    status: 2,
                    url: "/simplev/visualization",
                    children: [
                        { name: "视频中心", color: "#3D59A2", url: "/simplev/videoCourse" },
                        { name: "帮助文档", color: "#715EB5", url: "/simplev/help" },
                        { name: "资料中心", color: "#C47958", url: "/simplev/information" },
                        // { name: "问答中心", color: "#2A9C68", url: "/simplev/questions" },
                    ]
                },
                { name: "关于我们", status: 1, url: "/simplev/about" },
                { name: "更多产品", status: 1, url: "/simplev/products" },
            ],
        }
    },
    components: {
        heads,
        Footer
    },
    watch: {
    $route: {
      handler: function (to) {
        if(to.path == "/simplev/apply"){
          this.footerFlag=false
        }else{
          this.footerFlag=true
        }
      },
      immediate: true,
    }

  },
    created() {
        let log = document.getElementById("logo");
        if (log) log.remove();
        document.title = "原世界 SimpleV数据可视化平台";
        let links = document.createElement("link");
        links.rel = "icon";
        links.id = "logo";
        links.href = this.url;
        document.head.appendChild(links);
    },
    mounted() {
        this.$bus.$on('showApply',(falg)=>{
            console.log(falg)
            this.footerFlag=falg
        })
    },
    methods: {
        goHome(){
            this.$router.push('/simplev/home');
        }
    },
}
</script>
<style lang="less" scoped>
.logo{
    margin-left: 40px;
    // width: 225px;
    // height: 47px;
    width:180px;
    height: 38px;
    cursor: pointer;
}
</style>
