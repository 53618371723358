<template>
    <div class="subscribe">
        <div class="subscribe_box">
            <p class="subscribe_box_text">轻松设计 高效搭建，减少3倍设计改稿与开发运维工作量</p>
            <div class="subscribe_box_footer">
                <span class="subscribe_box_btn" @click="onClick" >立即体验</span>
                <router-link target="_blank" to="/simplev/apply"><span class="subscribe_box_btn subscribe_box_btn2">立即注册</span></router-link>
            </div>
        </div>
    </div>
</template>

<script>
export default {

    methods :{
        onClick(){
            window.open('https://simplev.yuanworldcloud.com/simplev');
        }
    }
}
</script>

<style lang="less" scoped>
.subscribe{
    width: 100%;
    height: 200px;
    background: url(../../assets/image/subscribe.png) no-repeat ;
    background-size: 100% 100%;
    .subscribe_box{
        padding:48px 0  0;
        width: 1600px;
        margin: auto;
        .subscribe_box_text{
            font-size: 28px;
            font-family: PingFang-Medium;
            font-weight: 400;
            color: #444444;
        }
        .subscribe_box_footer{
            display: flex;
        }
        .subscribe_box_btn{
            display: inline-block;
            cursor: pointer;
            width: 110px;
            height: 36px;
            border-radius: 4px;
            text-align: center;
            line-height:36px;
            font-size: 14px;
            font-family: PingFang-Medium;
            color: #FFFFFF;
            margin-right: 24px;
            background: #3F89FF;
            margin-top: 30px;
            transition: 0.5s;
            &:hover{
                background: #5395FF;
                box-shadow: 0px 4px 8px 0px rgba(70,129,225,0.3);
                margin-top: 25px;
            }
        }
        .subscribe_box_btn2{
            border: 1px solid #B1B3B6;
            color: #666666;
            background: rgba(63, 137, 255, 0);
            &:hover{
                background: #3F89FF;
                box-shadow: 0px 4px 8px 0px rgba(70,129,225,0.302);
                margin-top: 25px;
                color: #FFFFFF;
                border: 1px solid #3F89FF;
            }
        }
    }
}
</style>